<template>
  <Accordion>
    <AccordionTab :active="true" header="1. How do I search for my Creators?">
      <p>From Manage page, click on the third tab from the sub navigation.</p>
      <p>
        You will see list of creators listed. Feel free to use search space to
        find your creators instantly. You can type the name or email to find an
        entry or you could choose any filters that can fit well with your
        creator's criteria.
      </p>
    </AccordionTab>
    <AccordionTab header="2. How do I get to the Mastersheets?">
      <p>
        Use the sub navigation on the far left and click the third icon. This is
        the Mastersheets overview page. As the mastersheets contain a lot of
        information, we have split the data between, accounts, YouTube,
        Creators, Payments and Social.
      </p>
    </AccordionTab>
    <AccordionTab header="3. If I want to edit the Mastersheet?">
      <p>
        Click on the pencil icon to edit the info related to the channel
        account.
      </p>
    </AccordionTab>
    <AccordionTab header="4. How do I search or filter?">
      <p>
        OMNI has a master search for Creators and Managers at the top. There are
        also secondary filters on the Mastersheets which allow you to really
        focus on the data you want.
      </p>
    </AccordionTab>
    <AccordionTab header="5. How do I Download/Export?">
      <p>Click on Export page on the left side of main navigation.</p>
      <p>
        Choose your file selection through the filters given, Click export
        button on the right side. Now it's done!
      </p>
      <p>
        If you want more detailed data, use the filters on the managed pages or
        in the Mastersheets then export (top right blue button)
      </p>
      <p>Having download/export problem? Contact us here.</p>
    </AccordionTab>
    <AccordionTab header="6. Do you have a User Guide?">
      <p>
        Yes,
        <a
          href="https://docs.google.com/presentation/d/1OM0DlhSsPHX0dsRYAhIPpcWgTxyiHpqJI-yRAVBvCpg/edit#slide=id.g8b14972048_0_4"
          >click here</a
        >
        to access the Full OMNI User Guide
      </p>
    </AccordionTab>
  </Accordion>
</template>
<script>
export default {
  name: "Guide"
};
</script>
<style scoped></style>
